import React from 'react';
import Header from '../components/Header';
// import elegantDress5 from "../assets/images/elegant_dress_5.jpg";
import jessicaFotoBg from "../assets/images/photo_jessica-bg.png";
import training1 from "../assets/images/training1.jpg";
import training2 from "../assets/images/training2.jpg";
import measure_dress from "../assets/images/measure_dress.png";
import { Accordion, AccordionItem, Button, useDisclosure } from "@nextui-org/react";
import MetaPixel from '../components/MetaPixel';
import UTMs from '../components/UTMs';
import ModalLead from '../components/ModalLead';

const linkKirvano = "https://pay.kirvano.com/8a9322fb-9613-450d-8859-b71f981fc58f";

function TresPV() {
  const modalProps = useDisclosure();

  return (
    <>
      <MetaPixel />
      <UTMs />
      <main className="flex flex-col items-center justify-center h-full space-y-[100px] max-w-5xl mx-auto p-5 pb-10">
        <Header returnButton />

        <section className="flex flex-col items-center space-y-5 p-5 border rounded-md z-10">
          {/* <h1 className="uppercase text-center text-4xl md:text-4xl text-bordo font-bold leading-[1]"> Entre no vestido dos seus sonhos em 3 semanas!</h1> */}
          <h1 className="uppercase text-center text-4xl md:text-4xl text-bordo font-bold leading-[1]"> Pronta para alcançar sua melhor versão em apenas 3 semanas? </h1>
          <h2 className="text-center text-2xl md:text-2xl text-slate-600 font-medium leading-[1]">Método rápido, <strong> saudável </strong> e eficiente para perder peso e medidas. </h2>
          <img src={measure_dress} alt="emagreca de vez" className="w-[700px] rounded-lg"/>
          {/* <span className="flex items-center space-x-3">
            <BsClock className="text-slate-600 text-lg"/> <p className="text-slate-600 text-lg"> Acesso Vitalício </p>
          </span> */}
          {/* <div>
            <span className='text-center text-2xl md:text-2xl text-slate-600 font-medium leading-[1]'>por  </span>
            <span className='text-center text-4xl md:text-4xl text-slate-600 font-medium leading-[1]'>10x </span>
            <span className='text-center text-6xl md:text-7xl text-slate-600 font-medium leading-[1]'>R$ 11,00 </span>
          </div> */}
          <a href={linkKirvano} className="px-5 py-5 w-64 text-center rounded-md text-white bg-3spv-verde-escuro hover:bg-3spv-verde duration-300 shadow-lg text-xl"> Começar Agora! </a>
        </section>

        <section className="flex flex-col items-center md:flex-row my-5 md:my-0 w-full space-y-5 md:space-x-5">
          <article className="text-bordo flex flex-col w-full space-y-4">
            <h2 className='text-4xl md:text-4xl text-center text-bordo font-bold leading-[1]'> Você precisa de apenas 3 semanas para emagrecer!</h2>
            
            <p className="text-lg md:text-xl text-slate-600"> Entendo como é importante se sentir bem em um evento especial. Todos queremos que aquela roupa perfeita realce o nosso melhor. Seja um <strong>casamento, festa, formatura ou qualquer ocasião importante,</strong> estar confortável e confiante faz toda a diferença.</p>

            <p className="text-lg md:text-xl text-slate-600"> Pensando nisso, criamos o Programa 3 Semanas para o Vestido: uma estratégia de emagrecimento saudável de curto prazo, <strong>sem passar fome e sem dietas mirabolantes!</strong> Em apenas 3 semanas, você vai ganhar confiança e conquistar a melhor versão de si mesma!</p> 
          </article>
        </section>

        <section className="flex flex-col items-center md:flex-col my-5 md:my-0 w-full space-y-5 md:space-x-5">
          <h2 className='text-4xl md:text-4xl text-center text-bordo font-bold leading-[1]'> Como vai funcionar?</h2>
            <div className="flex flex-col items-center md:flex-row my-5 md:my-0 w-full space-y-5 md:space-x-5">
              <article className="text-bordo flex flex-col w-full space-y-4">
                <p className="text-lg md:text-xl text-slate-600">O programa <strong>3 Semanas para o Vestido</strong> é um desafio cuidadosamente elaborado para ajudá-la a entrar no vestido e se sentir confiante e radiante no seu evento. </p>

                <p className="text-lg md:text-xl text-slate-600"> Com três módulos únicos, cada semana oferece um plano com: <strong>Lista de compras, dieta, receitas e o treino </strong> 
              específico para resultados rápidos e saudáveis. <strong>Nada de dietas mirabolantes ou passar fome</strong> - apenas um guia prático que funciona!</p> 

                <p className="text-lg md:text-xl text-slate-600">Aqui, não prometemos milagres, mas com o seu foco e determinação, vamos transformar seu corpo para você <strong>entrar no vestido e arrasar!</strong></p> 

                <p className="text-lg md:text-xl text-slate-600"><strong> Seu evento está chegando, então comece hoje e veja a diferença!</strong> </p> 
              </article>
              <article className="flex flex-col justify-around w-full space-y-5">
                <div className="flex flex-col w-auto h-auto p-5 bg-verde opacity-90 rounded-xl shadow-sm  duration-300">
                  <h3 className="text-white text-2xl font-medium"> 1 Semana </h3>
                  {/* <p className="text-white text-xl"> Dieta Anti Inflamatória </p> */}
                  <p className="text-white text-lg leading-none"> Purificando o corpo para um novo começo </p>
                </div>
                <div className="flex flex-col w-auto h-auto p-5 bg-bordo opacity-90 rounded-xl shadow-sm  duration-300">
                  <h3 className="text-white text-2xl font-medium"> 2 Semana </h3>
                  {/* <p className="text-white text-xl"> Dieta Lowcarb </p> */}
                  <p className="text-white text-lg leading-none"> Reduzindo carboidratos para acelerar resultados </p>
                </div>
                <div className="flex flex-col w-auto h-auto p-5 bg-laranja opacity-90 rounded-xl shadow-sm  duration-300">
                  <h3 className="text-white text-2xl font-medium"> 3 Semana </h3>
                  {/* <p className="text-white text-xl"> Dieta Cetogênica </p> */}
                  <p className="text-white text-lg leading-none"> Queimando gordura com força máxima </p>
                </div>
              </article>
            </div>
          </section>

        <section className="flex flex-col w-full">
          <h2 className='text-4xl text-center text-bordo font-bold leading-[1] mb-5 md:self-center'> O que você receberá? </h2>
          <div className="mt-6 grid grid-cols-2 gap-2 md:grid-cols-3 lg:mt-8 z-10">
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                {/* <BsWhatsapp className="text-slate-600" size={48}/> */}
                  <p className="text-slate-600 text-lg uppercase font-medium"> Lista de compras para cada semana </p>
              </span>
            </div>
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                {/* <BsCalendar3 className="text-slate-600" size={20}/> */}
                  <p className="text-slate-600 text-md uppercase font-medium"> Dieta para cada semana </p>
              </span>
            </div>
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                {/* <BiFoodMenu className="text-slate-600" size={54}/> */}
                  <p className="text-slate-600 text-md uppercase font-medium"> Ebook de receitas para cada semana </p>
              </span>
            </div>
            {/* <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                  <p className="text-slate-600 text-lg uppercase font-medium"> Plano de exercícios feito por Personal Trainer; dentro e fora da academia </p>
              </span>
            </div> */}
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                {/* <BsCalendar3 className="text-slate-600" size={20}/> */}
                  <p className="text-slate-600 text-md uppercase font-medium"> Quadro para voce acompanhar seu progresso e medir os resultados </p>
              </span>
            </div>
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                {/* <BsCalendar3 className="text-slate-600" size={20}/> */}
                  <p className="text-slate-600 text-md uppercase font-medium"> Lista de substituições dos alimentos, caso tenha alguma preferência</p>
              </span>
            </div>
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                {/* <BsCalendar3 className="text-slate-600" size={20}/> */}
                  <p className="text-slate-600 text-md uppercase font-medium"> Desafio da hidratação </p>
              </span>
            </div>
            
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                {/* <BsCalendar3 className="text-slate-600" size={20}/> */}
                  <p className="text-slate-600 text-md uppercase font-medium"> Estratégias mentais para o sucesso </p>
              </span>
            </div>
          
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                {/* <TfiBook className="text-slate-600" size={32}/> */}
                  <p className="text-slate-600 text-md uppercase font-medium"> 4 Ebooks extras *</p>
              </span>
            </div>
           
          </div>
          <h6 className="text-start text-md text-slate-600 font-medium mt-3"> * opcional </h6>
        </section>
        
        <section className="flex flex-col w-full items-center space-y-2 md:space-x-2">
          <h2 className='text-4xl text-center text-bordo font-bold leading-[1]'> E MAIS um plano de exercícios físicos * </h2>
          <h3 className="text-center text-2xl md:text-2xl text-slate-600 font-medium leading-[1]"> elaborado pelo nosso Personal Trainer parceiro, para fazer em casa, na rua ou na academia. </h3>
          <div className="flex flex-col md:flex-row gap-1">
            <img src={training1} alt="mulher correndo" className="w-[350px] h-[350px] md:h-auto rounded-lg object-cover z-10"/>
            <img src={training2} alt="mulher na academia" className="w-[350px] h-[350px] md:h-auto rounded-lg object-cover z-10"/>
          </div>
          <h6 className="text-start text-md text-slate-600 font-medium"> * opcional </h6>
        </section>

        <section className="flex flex-col items-center md:flex-row my-5 md:my-0 w-full space-y-5 md:space-x-5">
          <article className="text-bordo flex flex-col w-full space-y-4">
            <h2 className='text-2xl text-center text-slate-600 font-bold leading-[1]'> Quer saber mais sobre como o programa funciona? Baixe agora mesmo o nosso Planner de 21 dias gratuitamente!</h2>
            <Button className="px-5 py-5 w-80 text-center text-md rounded-md text-white bg-[#48BFDB] duration-300 shadow-lg text-xl mx-auto h-[68px]" onPress={modalProps.onOpen}>Baixar Planner Grátis</Button>
          </article>
        </section>

        <section className="flex flex-col items-center space-y-5 md:space-x-5">
          <h2 className='text-4xl text-bordo text-center font-bold leading-[1] mb-5 md:self-center'> Ainda não me conhece? </h2>
          <div className="flex flex-col md:flex-row items-center justify-center space-y-5 md:space-x-5">
            <img src={jessicaFotoBg} alt="jessica vilela foto" className="w-[250px] sm:w-[350px] rounded-md"/>
            <div>
              <p className="text-lg md:text-xl text-slate-600"> Sou Jéssica Vilela, nutricionista, cearense e apaixonada por ajudar pessoas a atingirem uma vida mais saudável de forma prática e equilibrada. Sou formada em Nutrição, com pós-graduação em Nutrição Esportiva, venho construindo uma carreira dedicada a promover saúde sem extremos, com <strong> mais de 1.400 atendimentos realizados.</strong></p>
              <br/>
              <p className="text-lg md:text-xl text-slate-600">Como empreendedora, influenciadora, mãe e esposa, acredito que uma alimentação saudável é aquela que se adapta à vida real e traz mais bem-estar e alegria ao nosso dia a dia. Sou apaixonada pela família, pelos amigos, pelo esporte e pelo poder transformador de uma nutrição consciente e equilibrada.</p>
              <br/>
              <p className="text-lg md:text-xl text-slate-600">Agradeço por estar aqui e por confiar no meu trabalho! Vamos juntas nessa jornada em busca da melhor versão de você mesma!</p>
            </div>
          </div>
        </section>

        <section className="flex flex-col w-full items-center space-y-2 md:space-x-2">
            <h2 className='text-4xl text-center text-bordo font-bold leading-[1] mb-5'> O que dizem sobre o programa? </h2>
            <div className="flex flex-col md:flex-row w-full space-y-2 md:space-x-2 md:space-y-0 z-10">
              <div className="flex flex-col justify-between md:w-1/4 h-auto p-5 bg-bege rounded-xl shadow-lg space-y-5 hover:scale-105 transition-scale duration-300">
                <p className="text-slate-600 text-md"> "Muito obrigada por tudo de maravilhoso que você me proporcionou, o que eu aprendi lhe seguindo e o que eu aprendi aqui levo pra minha vida e da minha família. Que Deus lhe abençoe sempre e cada dia mais lhe fortaleça na sua missão. Muito obrigada mesmo de coração ❤️. Vou sentir falta de acordar com o bom dia do grupo, mas to colada no Instagram 😘" </p>
                <h3 className="text-slate-600 text-md self-end font-medium"> Isabel N. </h3>
              </div>
              <div className="flex flex-col justify-between md:w-1/4 h-auto p-5 bg-bege rounded-xl shadow-lg space-y-5 hover:scale-105 transition-scale duration-300">
                <p className="text-slate-600 text-md"> "O programa pra mim tá sendo incrível aprendi muitas coisas, a plataforma, as dicas aqui os vídeos o estímulo das colegas, top super indico 🥰" </p>
                <h3 className="text-slate-600 text-md self-end font-medium"> Silvelene V. </h3>
              </div>
              <div className="flex flex-col justify-between md:w-1/4 h-auto p-5 bg-bege rounded-xl shadow-lg space-y-5 hover:scale-105 transition-scale duration-300">
                <p className="text-slate-600 text-md"> "Dra. Jéssica o programa é maravilhoso, as informações e conteúdo me tirou muitas dúvidas. Agora estou comendo saudável com equilíbrio é o melhor sem aquelas neuras que tanto sofria em toda dieta que fazia l. Parabéns e obrigada pela ajuda, pena que só foram 3 semanas , mas eu pretendo levar os ensinamentos para vida toda 🥰🥰🥰" </p>
                <h3 className="text-slate-600 text-md self-end font-medium"> Clara </h3>
              </div>
              <div className="flex flex-col justify-between md:w-1/4 h-auto p-5 bg-bege rounded-xl shadow-lg space-y-5 hover:scale-105 transition-scale duration-300">
                <p className="text-slate-600 text-md"> "Jéssica o programa tá incrível! Estou me sentindo como a muito tempo não sentia. Como a alimentação interfere em todos os aspectos da vida. Me sinto muito mais disposta, menos ansiosa e estressada. E já Deco ter perdido pelo menos uns 6 quilos. A plataforma cheia de conteúdos maravilhosos e vídeos super práticos. Eu amei, obrigada" </p>
                <h3 className="text-slate-600 text-md self-end font-medium"> Luana </h3>
              </div>
            </div>
        </section>

        <section className="flex flex-col items-center space-y-5 md:space-x-5 z-10">
          <h2 className='text-2xl text-bordo font-bold leading-[1] mb-5 md:self-center'> Você está a um passo de mudar sua rotina e sentir-se maravilhosa! Não deixe para depois: comece sua transformação agora! </h2>
          {/* <img src={elegantDress5} alt="3 Semanas para o Vestido logo" className="w-[300px] sm:w-[300px] rounded-md"/> */}
          <a href={linkKirvano} className="px-5 py-5 w-80 text-center text-md rounded-md text-white bg-3spv-verde-escuro hover:bg-3spv-verde duration-300 shadow-lg text-xl"> Quero começar agora! </a>
        </section>

        <section className="flex flex-col items-center space-y-5 md:space-x-5 w-full z-10">
          <h2 className='text-4xl text-bordo font-bold leading-[1] mb-5 md:self-center'> Perguntas frequentes </h2>
            <Accordion variant="bordered">
            <AccordionItem key="1" aria-label="Accordion 1" title="O que é o programa 3 Semanas para o Vestido?">
              O programa é um desafio intensivo de três semanas, criado especialmente para quem deseja perder medidas de forma saudável e rápida para entrar em uma roupa especial. Ele combina alimentação equilibrada, hidratação e exercícios diários para promover a transformação sem extremos.
            </AccordionItem>
            <AccordionItem key="2" aria-label="Accordion 2" title="Como vou receber o material?">
              Assim que a compra for concluída, você terá acesso área do aluno da plataforma, onde vão estar disponíveis os ebooks em seus respectivos módulos. Todo o conteúdo está disponível em formato digital para que você possa acessá-lo onde e quando quiser.
            </AccordionItem>
            <AccordionItem key="3" aria-label="Accordion 3" title="Preciso seguir o programa exatamente como está para ver resultados?">
              Sim! Para alcançar os melhores resultados, é importante seguir o plano de alimentação e exercícios conforme as orientações. Cada etapa foi pensada para otimizar a perda de medidas e garantir um progresso saudável ao longo das três semanas.
            </AccordionItem>
            <AccordionItem key="4" aria-label="Accordion 4" title=" E se eu tiver restrições alimentares?">
              O programa inclui substituições e adaptações para tornar a dieta mais flexível e acessível para diferentes necessidades. Assim, você pode ajustar o plano para atender a restrições específicas sem comprometer os resultados.
            </AccordionItem>
            <AccordionItem key="5" aria-label="Accordion 5" title="Quanto tempo preciso dedicar aos exercícios?">
              Os exercícios diários são rápidos e eficazes, pensados para quem tem uma rotina corrida. Eles levam em média 30 a 60 minutos por dia e podem ser feitos em casa, sem a necessidade de equipamentos complexos, ou na academia.
            </AccordionItem>
            <AccordionItem key="6" aria-label="Accordion 6" title="Preciso de experiência com dietas ou exercícios para participar?">
              Não! O programa foi desenhado para todos os níveis, do iniciante ao avançado. Ele traz orientações detalhadas para que qualquer pessoa possa seguir o plano de maneira prática e segura.
            </AccordionItem>
            <AccordionItem key="7" aria-label="Accordion 7" title="O programa exige alimentos caros ou difíceis de encontrar?">
              Não! Os alimentos sugeridos no programa são acessíveis e fáceis de encontrar em qualquer supermercado. Também oferecemos opções de substituição para tornar o plano prático e adaptável à sua rotina.
            </AccordionItem>
            <AccordionItem key="8" aria-label="Accordion 8" title="Em quanto tempo posso esperar ver os primeiros resultados?">
              Embora cada corpo responda de forma diferente, muitos participantes relatam mudanças visíveis e perda de medidas já na primeira semana. Com dedicação ao plano, você verá resultados progressivos ao longo das três semanas.
            </AccordionItem>
            <AccordionItem key="9" aria-label="Accordion 9" title="Os exercícios podem ser feitos por pessoas de qualquer idade?">
              Os exercícios são acessíveis para a maioria das pessoas, mas é sempre recomendável consultar um profissional de saúde, especialmente se você tiver alguma restrição física. Todos os exercícios são adaptáveis para respeitar o seu ritmo e condição física.
            </AccordionItem>
            <AccordionItem key="10" aria-label="Accordion 10" title="Quanto peso posso esperar perder em 3 semanas?">
              Os resultados variam de pessoa para pessoa, é muito difícil dar um número. Lembre-se, o foco é perder medidas de forma saudável, então os resultados podem ir além da balança.
            </AccordionItem>
            <AccordionItem key="11" aria-label="Accordion 11" title="E se eu não gostar do programa?">
              Estamos comprometidos com a sua satisfação. Se, por algum motivo, o programa não atender às suas expectativas, oferecemos uma política de reembolso. Entre em contato conosco nos primeiros dias e garantiremos o suporte necessário.
            </AccordionItem>
          </Accordion>
        </section>  

        <section className="flex flex-col items-center space-y-5 md:space-x-5 z-10">
          <h2 className='text-4xl md:text-4xl text-bordo font-bold leading-[1] mb-5 md:self-center'> Este é o seu momento! Não perca mais tempo! 3 semanas podem fazer toda a diferença. Garanta agora o seu acesso ao programa! </h2>
          {/* <img src={elegantDress5} alt="3 Semanas para o Vestido logo" className="w-[300px] sm:w-[300px] rounded-md"/> */}
          <a href={linkKirvano} className="px-5 py-5 w-80 text-center text-md rounded-md text-white bg-3spv-verde-escuro hover:bg-3spv-verde duration-300 shadow-lg text-xl"> Comece sua transformação agora! </a>
        </section>

      </main>
      <ModalLead {...modalProps} />
    </>
  );
}

export default TresPV;
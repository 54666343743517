import React from 'react';
import { Helmet } from 'react-helmet';

function UTMs() {
  return (
    <Helmet>
      <script
        src="https://cdn.utmify.com.br/scripts/utms/latest.js"
        data-utmify-prevent-subids
        async
        defer
      ></script>
    </Helmet>
  );
}

export default UTMs; 
import React from 'react';
import { Helmet } from 'react-helmet';

function MetaPixel() {
  return (
    <Helmet>
      <script>
        window.pixelId = "67334451cef645a279914dbc";
        var a = document.createElement("script");
        a.setAttribute("async", "");
        a.setAttribute("defer", "");
        a.setAttribute("src", "https://cdn.utmify.com.br/scripts/pixel/pixel.js");
        document.head.appendChild(a);
      </script>
    </Helmet>
  );
}

export default MetaPixel; 
import { 
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "@nextui-org/react";
import React from "react";

export default function ModalLead({ isOpen, onOpenChange }) {

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const validateEmail = (value) => value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);

  const isInvalid = React.useMemo(() => {
    if (email === "") return false;

    return validateEmail(email) ? false : true;
  }, [email]);


  const downloadPlanner = (onClose) => {
    setLoading(true);

    fetch('https://addlead-jqfvghdwpa-uc.a.run.app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, email, phone })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(() => {
      setName('');
      setPhone('');
      setEmail('');
      onClose();
      var file_path = 'https://github.com/victommasi/jessicavilela-site/blob/main/public/planner21dias.pdf?raw=true';
      var a = document.createElement('a');
      a.href = file_path;
      a.download = 'planner21dias.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement={'center'}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 w-full"> Preencha os campos </ModalHeader>
              <ModalBody>
                <form id="lead-form" className="flex flex-col gap-3">
                  <Input
                    isRequired
                    value={name}
                    type="text"
                    placeholder="Seu nome"
                    label="Nome"
                    variant="bordered"
                    onValueChange={setName}
                  />
                  <Input
                    isRequired
                    value={phone}
                    type="tel"
                    placeholder="Seu telefone/WhatsApp"
                    label="Telefone"
                    variant="bordered"
                    onValueChange={setPhone}
                  />
                  <Input
                    isRequired
                    value={email}
                    type="email"
                    placeholder="Insira seu email"
                    label="Email"
                    variant="bordered"
                    isInvalid={isInvalid}
                    color={isInvalid ? "danger" : ""}
                    errorMessage="Insira um email válido"
                    onValueChange={setEmail}
                    />
                </form>
              </ModalBody>
              <ModalFooter>
                <Button variant="light" onPress={onClose}>
                  Sair
                </Button>
                <Button color="primary" isDisabled={isLoading || isInvalid || email === ''} onClick={() => downloadPlanner(onClose)}>
                  {!isLoading ? 'Baixar': 'Carregando...'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}